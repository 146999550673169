<template>
  <lxp-modal
    :show.sync="showModal"
    id="add-user-modal"
    centered
    title="Add User"
    @modal-close="onModalClose"
  >
    <div class="add-user__content">
      <p class="add-user__content--heading">
        First, enter a username and password.
      </p>
      <p class="add-user__content--heading">
        Then, you’ll be able to edit more user options.
      </p>
      <ValidationObserver ref="add-user-observer">
        <div class="add-user__form">
          <div class="add-user__form--name" v-if="!isLoading">
            <ValidationProvider
              name="first name"
              rules="required"
              v-slot="{ errors }"
            >
              <LxpInput
                label="First Name"
                :hasError="errors[0] ? true : false"
                :supportText="errors[0] ? errors[0] : ''"
                :maxlength="150"
                v-model="addUser.first_name"
              />
            </ValidationProvider>
            <ValidationProvider
              name="last name"
              rules="required"
              v-slot="{ errors }"
            >
              <LxpInput
                label="Last Name"
                :hasError="errors[0] ? true : false"
                :supportText="errors[0] ? errors[0] : ''"
                :maxlength="150"
                v-model="addUser.last_name"
              />
            </ValidationProvider>
          </div>
          <div class="add-user__form--username" v-if="!isLoading">
            <ValidationProvider
              name="username"
              :rules="{ required: true, regex: /^[a-z0-9_]+$/ }"
              v-slot="{ errors }"
              :custom-messages="{
                regex: 'Username can be 0-9 , A-Z , a-z , or underscore ( _ )'
              }"
            >
              <LxpInput
                label="Username"
                :append="
                  `<img src='${require('@/assets/images/partner/user-permissions/account-grey.svg')}' alt='account'/>`
                "
                :hasError="errors[0] ? true : false"
                :supportText="
                  errors[0]
                    ? errors[0]
                    : 'Characters can be 0-9 , A-Z , a-z , or underscore ( _ )'
                "
                v-model="addUser.username"
              />
            </ValidationProvider>
          </div>
          <ValidationProvider
            v-if="!isLoading"
            name="email"
            rules="required|email"
            v-slot="{ errors }"
          >
            <LxpInput
              label="Email"
              :append="
                `<img src='${require('@/assets/images/partner/user-permissions/email.svg')}' alt='account'/>`
              "
              :hasError="errors[0] ? true : false"
              :supportText="errors[0] ? errors[0] : ''"
              v-model="addUser.email"
            />
          </ValidationProvider>

          <div class="add-user__form--permissions">
            <h5>Permissions</h5>
            <b-row>
              <b-col cols="12" class="add-user__form--permissions__div">
                <LxpRadio
                  text="Staff"
                  value="staff"
                  selectedVal="staff"
                  name="user-role"
                  id="user-role-staff"
                  @input="onInput"
                />

                <p class="upw-edit__note">
                  Designates whether the user can log into this admin console
                  site.
                </p>
              </b-col>
              <b-col cols="12" class="add-user__form--permissions__div">
                <LxpRadio
                  text="Admin (Superuser)"
                  value="admin"
                  selectedVal="staff"
                  name="user-role"
                  id="user-role-admin"
                  @input="onInput"
                />
                <p class="upw-edit__note">
                  Designates that this user has all permissions without
                  explicitly assigning them.
                </p>
              </b-col>
            </b-row>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <template #footer>
      <div class="add-user__footer">
        <lxp-button variant="outline-secondary1" @click="onSave('add-another')"
          >Save and add another</lxp-button
        >
        <lxp-button variant="secondary1" @click="onSave('add')"
          >Save</lxp-button
        >
      </div>
    </template>
  </lxp-modal>
</template>

<script>
import { LxpModal, LxpButton, LxpInput, LxpRadio } from "didactica";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { extend } from "vee-validate";
import { email } from "vee-validate/dist/rules";

extend("email", email);

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LxpModal,
    LxpButton,
    LxpInput,
    LxpRadio,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      showModal: false,
      addUser: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        role: "staff"
      },
      isLoading: false
    };
  },
  watch: {
    showModal(newValue) {
      this.$emit("update:show", newValue);
    },
    show() {
      this.showModal = this.show;
    }
  },
  computed: {
    roleOptions() {
      let arr = [
        {
          text: "Staff",
          value: "staff",
          disabled: false
        },
        {
          text: "Staff",
          value: "staff",
          disabled: false
        }
      ];
      return arr;
    }
  },
  methods: {
    onAddAnother() {
      this.isLoading = true;
      this.addUser = {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        role: "staff"
      };
      this.$refs["add-user-observer"].reset();
      // TODO temp fix refactoring needed
      setTimeout(() => {
        this.isLoading = false;
      }, 10);
    },
    onInput(e) {
      this.addUser.role = e;
    },
    onSave(type) {
      this.$refs["add-user-observer"].validate().then(res => {
        if (res) {
          this.$store
            .dispatch("createUser", this.addUser)
            .then(() => {
              this.$emit("addUser", this.addUser);
              if (type === "add-another") {
                this.onAddAnother();
              } else {
                this.showModal = false;
              }
            })
            .catch(err => {
              if (Object.keys(err).includes("non_field_errors")) {
                this.$refs["add-user-observer"].setErrors({
                  email: err["non_field_errors"]
                });
              } else {
                this.$refs["add-user-observer"].setErrors(err);
              }
            });
        }
      });
    },
    onModalClose() {
      this.onAddAnother();
    }
  }
};
</script>

<style lang="scss">
#add-user-modal {
  .add-user__content {
    .add-user__content--heading {
      @include body-regular;
      margin: 0 5%;
      text-align: center;
      &:nth-child(2) {
        margin-bottom: 8px;
      }
    }
    .add-user__form {
      .add-user__form--name {
        margin-bottom: 8px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 1rem;
        span {
          flex: 1;
        }
      }
      .add-user__form--username {
        margin-bottom: 8px;
      }
      .add-user__form--permissions {
        padding: 16px;
        border: 1px solid $brand-secondary1-100;
        border-radius: 16px;
        margin-top: 16px;
        h5 {
          @include body-regular(500, $brand-neutral-900);
          margin-bottom: 8px;
        }
        .add-user__form--permissions__div {
          padding: 8px 16px;
          .upw-edit__note {
            @include label-medium;
            color: $brand-neutral-300;
            margin: 8px 0 8px 0;
          }
        }
      }
    }
  }
  .add-user__footer {
    @include horizontal-space-between;
  }
}
</style>
